var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 兑换码设置 ")]},proxy:true},{key:"input",fn:function(){return [_c('a-input',{attrs:{"placeholder":"请输入兑换码名称","allowClear":""},model:{value:(_vm.queryParams.batchName),callback:function ($$v) {_vm.$set(_vm.queryParams, "batchName", $$v)},expression:"queryParams.batchName"}}),_c('a-button',{staticClass:"btn",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.onSearch}},[_vm._v("搜索")]),_c('a-button',{staticClass:"all_boder_btn",on:{"click":function($event){return _vm.toDetail(1)}}},[_vm._v("新建兑换码")])]},proxy:true}])}),_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item,index){ return index; },"columns":_vm.columns,"loading":_vm.loading,"data-source":_vm.tableData,"locale":{emptyText: '暂无数据'},"pagination":{
        total:_vm.total,
        current:_vm.queryParams.pageNum,  
        defaultpageSize:_vm.queryParams.pageSize, 
        showSizeChanger: true,
        showTotal: function(total, range){
          return ("共" + total + "条")
        }
      }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, index){return [_vm._v(" "+_vm._s((_vm.queryParams.pageNum - 1) * _vm.queryParams.pageSize + index + 1)+" ")]}},{key:"operation",fn:function(item,row){return [_c('span',{staticClass:"blueText"},[_c('span',{on:{"click":function($event){return _vm.toDetail(2,row.id)}}},[_vm._v("码库详情")]),_vm._v(" | "),_c('span',{on:{"click":function($event){return _vm.toDetail(1,row.id)}}},[_vm._v("详情")]),_vm._v(" | "),_c('span',{on:{"click":function($event){return _vm.cancelData(row.id)}}},[_vm._v("未使用作废")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(row.getNum == 0 && row.redeemNum==0),expression:"row.getNum == 0 && row.redeemNum==0"}],on:{"click":function($event){return _vm.deleteData(row.id)}}},[_vm._v(" | 删除")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }