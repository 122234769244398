<template>
  <!-- 兑换码设置 -->
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        兑换码设置
      </template>
      <template #input>
        <a-input v-model="queryParams.batchName" placeholder="请输入兑换码名称" allowClear/>
        <a-button
          type="primary"
          class="btn"
          @click="onSearch"
          :loading="loading"
        >搜索</a-button>
        <a-button
          class="all_boder_btn"
          @click="toDetail(1)"
        >新建兑换码</a-button>
      </template>
    </HeaderBox>

    <a-table 
        class="table-template"
        :rowKey="(item,index)=>index"
        :columns="columns" 
        :loading="loading"
        :data-source="tableData" 
        :locale="{emptyText: '暂无数据'}" 
        @change="onPage"
        :pagination="{
          total:total,
          current:queryParams.pageNum,  
          defaultpageSize:queryParams.pageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
            return `共${total}条`
          }
        }">
        <!-- 序号 -->
      <template
        slot="index"
        slot-scope="item, row, index"
      >
        {{ (queryParams.pageNum - 1) * queryParams.pageSize + index + 1 }}
      </template>

      <!-- 操作 -->
      <template slot="operation" slot-scope="item,row">
        <span class="blueText">
          <span @click="toDetail(2,row.id)">码库详情</span> | 
          <span @click="toDetail(1,row.id)">详情</span> | 
          <span @click="cancelData(row.id)">未使用作废</span>
          <span v-show="row.getNum == 0 && row.redeemNum==0" @click="deleteData(row.id)"> | 删除</span>
        </span>
      </template>
    </a-table>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "兑换码名称",
    align: "center",
    dataIndex: "batchName",
  },
  {
    title: "适用商品",
    align: "center",
    dataIndex: "productName",
  },
  {
    title: "兑换权益数",
    align: "center",
    dataIndex: "totalNum",
  },
  {
    title: "领取人数",
    align: "center",
    dataIndex: "getNum",
  },
  {
    title: "核销人数",
    align: "center",
    dataIndex: "redeemNum",
  },
  {
    title: "创建人",
    align: "center",
    dataIndex: "nickName",
  },
  {
    title: "生成时间",
    align: "center",
    dataIndex: "createTime",
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: {
      customRender: "operation",
    },
  },
];
export default {
  // 可用组件的哈希表
  components: {HeaderBox},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      columns,
      loading:false,
      tableData:[],
      total:0,
      queryParams: {
        batchName:'',
        pageNum: 1, //页码
        pageSize: 10, // 页数
      },
    }
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e){
      this.queryParams.pageNum = e.current
      this.queryParams.pageSize = e.pageSize
      this.getList()
    },

    onSearch() {
      this.queryParams.pageNum = 1;
      this.getList();
    },

    // 查询兑换券列表
    getList(e) {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/redeem/list",
        params: this.queryParams,
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },

    // 删除兑换券配置
    deleteData(id) {
      let _this = this;
      this.$confirm({
        title: "兑换券删除后将不可再使用，是否确认?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/redeem/configDel/" + id,
              method: "DELETE",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("删除成功");
                _this.getList();
              }
            });
        },
        onCancel() {},
      });
    },

    // 作废未使用的券
    cancelData(id) {
      let _this = this;
      this.$confirm({
        title: "未使用兑换券作废后将不可再使用，是否确认?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/redeem/nullifyAll/" + id,
              method: "PUT",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("操作成功");
              }
            });
        },
        onCancel() {},
      });
    },

    // 页面跳转
    toDetail(type,batchId){
      if (type == 1){
        this.$router.push({
          path: "/admin/PromotionManage/exchangeAdd",
          query: { batchId: batchId },
        });
      } else if (type == 2){
        this.$router.push(
          "/admin/PromotionManage/codeDetail?batchId=" + batchId
        );
      }
    }
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.getList()
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.btn {
  margin: 0 24px 14px 0;
}
</style>
